.calendar-years {
  height: 80%;
  width: 100%;
}

.calendar-years__calendar {
  height: 100%;
  width: 100%;
}

.calendar-years__header {
  font-size: 0.875rem;
}

.calendar-years__body {
  font-size: 0.875rem;
}

.calendar-years__cell {
  text-align: center;
}

.calendar-years__year {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.calendar-years__year--current {
  background-color: var(--colors-primary);
  border-radius: 2px;
  color: var(--colors-white);
}
