.pagination-old {
  align-items: center;
  display: flex;
}

.pagination-old__move {
  background: none;
  border: none;
  color: var(--colors-grey-light);
  cursor: pointer;
  font-size: 0.9rem;
  outline: none;
}

.pagination-old__move--label {
  border: 1px solid var(--colors-grey);
  border-radius: 4px;
  color: var(--toggle-checked-track-color);
  padding: 4px 8px;
}

.pagination-old__move--prev {
  margin-right: 8px;
}

.pagination-old__move--next {
  margin-left: 8px;
}

.pagination-old__move--first {
  margin-right: 8px;
}

.pagination-old__move--last {
  margin-left: 8px;
}

.pagination-old__move-icon {
  &:after {
    border-bottom-width: 3px;
    border-right-width: 3px;
  }
}

.pagination-old__move-icon--prev {
  @extend .chevron;
  @extend .chevron--left;
}

.pagination-old__move-icon--next {
  @extend .chevron;
  @extend .chevron--right;
}

.pagination-old__pages {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.pagination-old__page {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 4px 8px;
  outline: none;
}

.pagination-old__page--active {
  background-color: var(--toggle-checked-track-color);
  border-radius: 4px;
  color: var(--colors-white);
}
