.drawer {
  background-color: var(--colors-white);
  display: flex;
  flex-direction: column;
  position: fixed;
  transition: transform 255ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 999;
}

.drawer--visible {
  transform: none !important;
}

.drawer--anchor-top {
  top: 0;
  transform: translateY(-100%);
  width: 100%;
}

.drawer--anchor-right {
  height: 100%;
  right: 0;
  top: 0;
  transform: translateX(100%);
}

.drawer--anchor-bottom {
  bottom: 0;
  transform: translateY(100%);
  width: 100%;
}

.drawer--anchor-left {
  height: 100%;
  left: 0;
  top: 0;
  transform: translateX(-100%);
}
