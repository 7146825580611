.caret {
  border: 6px solid var(--colors-black);
  display: inline-block;
  height: 0;
  width: 0;
}

.caret--up {
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
}

.caret--right {
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-left-color: transparent;
}

.caret--down {
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.caret--left {
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
}

.chevron {
  display: inline-block;
  height: 12px;
  position: relative;
  width: 12px;

  &::after {
    border-bottom: 2px solid;
    border-right: 2px solid;
    content: '';
    height: 10px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    width: 10px;
  }
}

.chevron--up {
  transform: rotate(-90deg);
}

.chevron--right {
  transform: rotate(-45deg);
}

.chevron--down {
  transform: rotate(45deg);
}

.chevron--left {
  transform: rotate(135deg);
}

.cross {
  display: inline-block;
  height: 14px;
  width: 18px;

  &:before,
  &:after {
    background-color: var(--colors-black);
    border-radius: 3px;
    content: '';
    display: block;
    height: 3px;
    margin: 5px 0;
  }

  &:before {
    transform: rotate(135deg);
  }

  &:after {
    transform: translateY(-8px) rotate(-135deg);
  }
}

.minus {
  display: inline-block;
  position: relative;

  &::after {
    background: var(--colors-black);
    content: "";
    height: 2px;
    left: -5px;
    position: absolute;
    top: 5px;
    width: 12px;
  }
}

.plus {
  background: var(--colors-black);
  display: inline-block;
  height: 12px;
  position: relative;
  width: 2px;

  &::after {
    background: var(--colors-black);
    content: "";
    height: 2px;
    left: -5px;
    position: absolute;
    top: 5px;
    width: 12px;
  }
}
