.toast {
  background-color: var(--toast-background-color);
  border-radius: 4px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.5), 0px 6px 10px 0px rgba(0, 0, 0, 0.4), 0px 1px 18px 0px rgba(0, 0, 0,  0.2);
  color: var(--colors-white);
  display: flex;
  min-width: 280px;
  opacity: 0;
  padding: 1em;
  position: fixed;
  transition: opacity .25s ease-out;
  z-index: 999;
}

.toast--visible {
  opacity: 100;
  transition: opacity .25s ease-in;
}

.toast--bottom-right {
  bottom: 24px;
  right: 24px;
}

.toast--bottom-middle {
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.toast--bottom-left {
  bottom: 24px;
  left: 24px;
}

.toast--left-middle {
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.toast--right-middle {
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.toast--top-left {
  left: 24px;
  top: 24px;
}

.toast--top-middle {
  left: 50%;
  top: 24px;
  transform: translateX(-50%);
}

.toast--top-right {
  right: 24px;
  top: 24px;
}
