.cleanbutton {
  background-color: inherit;
  border: none;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
  padding: 0;
  text-decoration: inherit;
}
