.accordion {
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.accordion__panel {
  border-bottom: 1px solid var(--colors-grey-light);
  overflow: hidden;
  position: relative;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }
}

.accordion__checkbox {
  cursor: pointer;
  height: 3.5em;
  margin: 0;
  opacity: 0;
  position: absolute;
  width: 100%;

  &:checked {
   ~ .accordion__header {
    .accordion__header-icon {
      &:after {
        transform: rotate(-180deg);
      }
    }
   }

    ~ .accordion__content {
      max-height: 100vh;
      transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
}

.accordion__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1em 2em 1em 1.2em;
}

.accordion__header-title {
  margin: 0.8em 0;
}

.accordion__header-icon {
  @extend .chevron;
  @extend .chevron--down;

  &:after {
    border-bottom-width: 3px;
    border-right-width: 3px;
    transition: transform 300ms;
  }
}

.accordion__content {
  max-height: 0;
  padding: 0 1.2em;
}
