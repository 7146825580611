.error-modal {
  border-radius: 0 0 4px 4px;
}

.error-modal__header {
  background-color: var(--colors-error);
  border-radius: 4px 4px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 1em;
}

.error-modal__title {
  color: var(--colors-white);
}

.error-modal__close {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  outline: none;
}

.error-modal__icon {
  @extend .cross;

  &:before,
  &:after {
    background-color: var(--colors-white);
  }
}

.error-modal__body {
  padding: 1em;
}

.error-modal__footer {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
}

.error-modal__ok {
  @extend .button;
}
