.avatar {
  @extend .circle;
  
  align-items: center;
  background-color: var(--colors-grey);
  color: var(--colors-white);
  display: flex;
  justify-content: center;
  user-select: none;
}

.avatar--small {
  height: 30px;
  font-size: 0.8rem;
  width: 30px;
}

.avatar--normal {
  height: 50px;
  font-size: 1.2rem;
  width: 50px;
}

.avatar--large {
  height: 70px;
  font-size: 2rem;
  width: 70px;
}

.avatar__avatar {
  border-radius: 50%;
  width: 100%;
}
