.calendar-default {
  height: 80%;
  width: 100%;
}

.calendar-default__calendar {
  height: 100%;
  width: 100%;
}

.calendar-default__header {
  font-size: 0.875rem;
}

.calendar-default__body {
  font-size: 0.875rem;
}

.calendar-default__cell {
  text-align: center;
}

.calendar-default__day {
  background: none;
  border: none;
  color: var(--colors-black);
  cursor: pointer;
  height: 20px;
  display: inline-block;
  outline: none;
  padding: 0;
  text-decoration: none;
  width: 20px;
}

.calendar-default__day--current {
  background-color: var(--colors-primary);
  border-radius: 2px;
  color: var(--colors-white);
}

.calendar-default__day--out-of-month {
  color: var(--colors-grey-light);
}

.calendar-default__day--selected {
  outline: 2px solid var(--colors-green);
}
