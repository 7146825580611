.range-slider {
  width: 100%;
}

.range-slider__slider {
  appearance: none;
  -moz-apperance: none;
  -webkit-appearance: none;
  border-radius: 5px;
  height: 4px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0.15, var(--colors-primary)),
    color-stop(0.15, var(--colors-primary))
  );
  outline: none;
  width: 100%;

  &::-moz-range-thumb {
    appearance: none;
    -moz-appearance: none;
    background-color: var(--colors-white);
    border: 2px solid var(--colors-primary);
    border-radius: 50%;
    cursor: grab;
    height: 14px;
    width: 14px; 
  }

  &::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    background-color: var(--colors-white);
    border: 2px solid var(--colors-primary);
    border-radius: 50%;
    cursor: grab;
    height: 14px;
    width: 14px;
  }
}

.range-slider__value {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5em;
}
