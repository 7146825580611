.wave-loader {
  font-size: 10px;
  height: 40px;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
}

@keyframes block-delay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
  }
  
  20% { 
    transform: scaleY(1.0);
  }
}

.wave-loader__wave {
  animation: block-delay 1.2s infinite ease-in-out;
  background-color: var(--colors-primary);
  display: inline-block;
  height: 100%;
  margin: 0 3px 0 0;
  width: 6px;

  &:nth-child(2) {
    animation-delay: -1.1s;
  }

  &:nth-child(3) {
    animation-delay: -1.0s;
  }

  &:nth-child(4) {
    animation-delay: -0.9s;
  }

  &:nth-child(5) {
    animation-delay: -0.8s;
    margin-right: 0;
  }
}
