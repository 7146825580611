.custom-scrollbar {
  overflow-y: auto;
  scrollbar-color: #888;
  scrollbar-width: thin;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }  
}

.custom-scrollbar--hidden {
  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
}
