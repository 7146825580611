.modal {
  border-radius: 5px;
  background-color: var(--colors-white);
  left: 50%;
  max-height: calc(100% - 64px);
  max-width: 600px;
  opacity: 1;
  position: absolute;
  transform: translate(-50%, 0);
  transition: transform .3s ease-out;
  width: 100%;
}

.modal--visible {
  opacity: 1;
  transform: translate(-50%, 25%);
  transition: transform .3s ease-out;
}
