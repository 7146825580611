.promptmodal {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9000;
  background-color: rgba(0, 0, 0, 0.6);
}

.promptmodal__panel {
  border-style: solid;
  border-width: 0.1em;
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.5em;
  padding: 1.25em;
}
.promptmodal__root .promptmodal__panel {
  min-width: 27.5em;
}

.promptmodal__buttons {
  margin-top: 2em;
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 0.5em;
}
.promptmodal__reverse-button-order {
  flex-direction: row-reverse;
  gap: 0.5em;
}

.promptmodal__buttons>button {
  border-style: solid;
  border-width: 0.1em;
  border-radius: 1000px;
  cursor: pointer;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  outline: none;
  font-size: 1.13em;
  height: 2em;
  width: 7em;
}

.promptmodal__no-button {
}
.promptmodal__ok-button,
.promptmodal__yes-button {
}
.promptmodal__cancel-button {
  margin-left: 2em;
}

.promptmodal__title {
  font-size: 1.7em;
  margin-top: 0;
}
.promptmodal__prompt {
  max-width: 27.5em;
  text-align: center;
}


