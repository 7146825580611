.buttoncontrol__dark,
.buttoncontrol__light {
  border: 1px solid #52586f;
  border-radius: 8px;
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
  height: 3em; /* 48px */
  padding: 0.75em 1.25em; /* 12px 20px */
  text-decoration: inherit;
}

.buttoncontrol__dark:disabled,
.buttoncontrol__light:disabled {
  border: none;
  color: rgba(51, 51, 51, 0.5);
  cursor: auto;
  background-color: #f5f6f8;
}

.buttoncontrol__light {
  background-color: #edf3fd;
  color: #333;
}
.buttoncontrol__dark {
  background-color: #394a7a;
  color: white;
}
