//todo: separate CSS for different grids

.modaldatagrid__table {
  width: 100%;
}

.modaldatagrid__table,
.modaldatagrid__table th:first-child {
  border-top-left-radius: 15px;
}
.modaldatagrid__table,
.modaldatagrid__table th:last-child {
  border-top-right-radius: 15px;
}

.modaldatagrid__table-body-cell {
  padding: 0.5em 1em;
  text-align: left;
  vertical-align: middle;
}

.modaldatagrid__table-body-row {
  height: 3.5em; /* 56px */
}
.modaldatagrid__table-body-cell:not(:first-child) {
  border-left: 1px solid #CCC;
}

/* Rows 2 and 3 are spacer rows. They will not have side borders, but they will have
   a horizontal border between them. */
.modaldatagrid__table-body-row:nth-child(2),
.modaldatagrid__table-body-row:nth-child(3) {
  height: 0.5em;
}
.modaldatagrid__table-body-row:nth-child(2)>.modaldatagrid__table-body-cell,
.modaldatagrid__table-body-row:nth-child(3)>.modaldatagrid__table-body-cell {
  border-left: none;
}

.modaldatagrid__table-body-row>.modaldatagrid__table-body-cell:last-child {
  border-left: none;
  padding-left: 0;
}
.modaldatagrid__table-body-row>.modaldatagrid__table-body-cell:last-child .radio{
  padding: 0;
}
.modaldatagrid__table-body-row>.modaldatagrid__table-body-cell:last-child .radio__radio{
  top: -0.3em;
}

.modaldatagrid__table-body-row:nth-child(2)>.modaldatagrid__table-body-cell {
  border-bottom: 1px solid #CCC;
}



.modaldatagrid__pagination-container {
  padding-top: 0.2em;
}

// .modaldatagrid__checkbox {
//   // appearance: none;
//   height: 18px;
//   outline: 2px solid #3168a8;
//   width: 18px;
// }

