@import './calendarDefault';
@import './calendarMonths';
@import './calendarYears';

.calendar {
  background: var(--colors-white);
  border: 1px solid var(--colors-grey);
  border-radius: 4px;
  height: 12em;
  padding: 0.5em;
  width: 15em;
}

.calendar__header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 1em;
}

.calendar__month {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

.calendar__year {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding-left: 1em;
  text-decoration: none;
}

.calendar__nav {
  display: flex;
  justify-content: flex-end;
}

.calendar__prev {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.calendar__next {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.calendar__prev-icon {
  @extend .chevron;
  @extend .chevron--left;
}

.calendar__next-icon {
  @extend .chevron;
  @extend .chevron--right;
}
