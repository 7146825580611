.newtoast {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 1em;
  left: 50%;
  pointer-events: none;
  position: fixed;
  top: 3em;
  transform: translateX(-50%);
  width: 15%;
  z-index: 11000;
}

.newtoast__toast {
  border-radius: 10px;
  cursor: pointer;
  padding: 1em;
  pointer-events: auto;
  text-align: center;
  transition-property: opacity;
  width: 100%;
}

.newtoast__errortype {
  background-color: rgba(255, 0, 0, 0.3);
}
.newtoast__warningtype {
  background-color: rgba(255, 255, 0, 0.3);
}

.newtoast__open {
  opacity: 1;
}

.newtoast__closing {
  opacity: 0;
}
