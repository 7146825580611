.debouncedTextbox {
  align-items: center;
  background: none;
  border: 1px solid #8396b4;
  border-radius: 12px;
  display: flex;
  font-size: 0.9rem;
  height: 48px;
  justify-content: space-between;
  min-width: 300px;
  outline: none;
  padding: 0 10px;
  position: relative;
}

.debouncedTextbox__legend {
  background-color: white;
  font-size: 0.9em;
  padding: 0 0.1em;
  position: absolute;
  top: -0.65em;
  z-index: 1;
}

.debouncedTextbox .debouncedTextbox__input {
  border: none;
  outline: none;
  width: 100%;
}


