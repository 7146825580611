.progress-ring__percent {
  text-anchor: middle;
}

.progress-ring__percent--xsmall {
  font-size: 0.5rem;
}

.progress-ring__percent--small {
  font-size: 0.8rem;
}

.progress-ring__percent--medium {
  font-size: 1rem;
}

.progress-ring__percent--normal {
  font-size: 1.2rem;
}

.progress-ring__percent--large {
  font-size: 1.6rem;
}

.progress-ring__percent--xlarge {
  font-size: 1.8rem;
}

.progress-ring__ring {
  stroke-dasharray: 0 0;
  stroke-dashoffset: 0;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 1s;
}
