.glossary {
  align-items: baseline;
  display: grid;
  gap: 1em;
  grid-template-columns: min-content 1fr;
}

.glossary__term {
  @extend .circle;

  align-items: center;
  color: var(--colors-white);
  display: flex;
  grid-column: 1;
  height: 30px;
  justify-content: center;
  position: sticky;
  top: 1em;
  width: 30px;
}

.glossary__description {
  border-bottom: 1px solid var(--colors-grey-light);
  grid-column: 2;
}

.glossary__title {
  font-weight: 600;
}

.glossary__definition {
  padding: 1em 0;
}
