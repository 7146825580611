.tooltip {
  display: inline-block;
  position: relative;
}

.tooltip__container {
  &:hover {
    ~ .tooltip__content {
      opacity: 100;
      transition: opacity z-index .25s ease-in;
      z-index: 1;
    }
  }
}

.tooltip__content {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  color: #fff;
  cursor: default;
  max-width: 300px;
  opacity: 0;
  padding: 5px 8px;
  position: absolute;
  text-align: center;
  transition: opacity .25s ease-out;
  white-space: nowrap;
  z-index: -1;

  &:after {
    content: "";
    border-width: 5px;
    border-style: solid;
    left: 50%;
    margin-left: -5px;
    position: absolute;
  }
}

.tooltip__content--visible {
  opacity: 100;
  transition: opacity z-index .25s ease-in;
  z-index: 1;
}

.tooltip__content--top {
  &:after {
    border-color: rgba(0, 0, 0, 0.5) transparent transparent transparent;
    top: 100%;
  }
}

.tooltip__content--right { 
  &:after {
    border-color: transparent rgba(0, 0, 0, 0.5) transparent transparent;
    margin: -5px 0 0;
    left: -10px;
    top: 50%;
  }
}

.tooltip__content--bottom {
  top: 150%;

  &:after {
    bottom: 100%;
    border-color: transparent transparent rgba(0, 0, 0, 0.5) transparent;
  }
}

.tooltip__content--left {
  &:after {
    bottom: 100%;
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.5);
    left: 100%;
    margin: -5px 0 0;
    top: 50%;
  }
}
