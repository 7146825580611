@keyframes spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner-loader {
  animation: spinner-animation 1s linear infinite;
  border: 6px solid var(--colors-white);
  border-top: 6px solid var(--colors-primary);
  border-radius: 50%;
  height: 40px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
}
