.rating {
  display: inline-block;
}

.rating__label {
  text-align: center;
}

.rating__ratings {
  display: inline-flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* POSSIBLY REFACTOR TO SHARE SELECTORS */
.rating__item {
  &:hover {
    > .rating__icon-cont {
      > .rating__icon {
        display: none;
      }

      > .rating__icon--filled {
        display: inline;
      }
    }

    ~ .rating__item {
      > .rating__icon-cont {
        > .rating__icon {
          display: none;
        }

        > .rating__icon--filled {
          display: inline;
        }
      }
    }
  }
}

.rating__item--selected,
.rating__item--active {
  > .rating__icon-cont {
    > .rating__icon--empty {
      display: none;
    }

    > .rating__icon--partial {
      display: none;
    }

    > .rating__icon--filled {
      display: inline;
    }
  }

  ~ .rating__item {
    > .rating__icon-cont {
      > .rating__icon--empty {
        display: none;
      }

      > .rating__icon--partial {
        display: none;
      }

      > .rating__icon--filled {
        display: inline;
      }
    }
  }
}

.rating__item--partial {
  > .rating__icon-cont {
    > .rating__icon--empty {
      display: none;
    }

    > .rating__icon--partial {
      display: inline;
    }

    > .rating__icon--filled {
      display: none;
    }
  }
}

.rating__item--disabled {
  pointer-events: none;
}

.rating__icon-cont {
  align-items: center;
  display: flex;
  padding: 0.2em 0.1em;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.rating__icon {
  color: var(--colors-primary);
  font-size: 1.225rem;
  margin: 0 0.1em;
  pointer-events: none;
}

.rating__icon--partial {
  display: none;
}

.rating__icon--filled {
  display: none;
}

.rating__scale {
  display: flex;
  justify-content: space-between;
  padding: 0 2px;
}
