.toggle {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 20px;
  padding: 0.1em 0 0 3em;
  position: relative;
}

.toggle__input {
  height: 0;
  opacity: 0;
  position: relative;
  width: 0;

  &:focus {
    box-shadow: 0 0 1px var(--toggle-checked-track-color);
  }

  &:checked {
    + .toggle__track {
      background-color: var(--toggle-checked-track-color);

      &:before {
        transform: translateX(-16px);
      }
    }
  }
}

.toggle__track {
  background-color: var(--toggle-unchecked-track-color);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
  width: 36px;

  &:before {
    background-color: white;
    bottom: 2px;
    content: "";
    height: 16px;
    right: 2px;
    position: absolute;
    transition: .4s;
    width: 16px;
  }
}

.toggle__track--round {
  border-radius: 34px;

  &:before {
    border-radius: 50%;
  }
}
