.button {
  background-color: var(--colors-primary);
  background-position: center;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0 0 4px var(--colors-grey);
  color: var(--colors-white);
  font-size: 16px;
  outline: none;
  padding: 10px 16px;
  text-transform: uppercase;
  transition: background 0.8s;

  &:hover {
    background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center / 15000%;
  }

  &:active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: background 0s;
  }
}

.button--secondary {
  background-color: var(--colors-white);
  color: var(--colors-black);

  &:hover {
    background: rgba(0, 0, 0, 0.04) radial-gradient(circle, transparent 1%, rgba(0, 0, 0, 0.04) 1%) center / 15000%;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.04);
    background-size: 100%;
    transition: background 0s;
  }
}
