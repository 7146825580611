@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

* {
	box-sizing: border-box;
}

html, body, #main {
	height: 100%;
}

body, button {
	font-display: swap;
	font-family: 'Poppins', 'Arial', 'Helvetica';
}

body {
	&:before {
		content: "mobile";
		display: none;  

		@media (min-width: $screen-lg) {
			content: "tablet"; 
		}  

		@media (min-width: $screen-xl) {
			content: "desktop";
		}
	}
}
