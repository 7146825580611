.radio {
  cursor: pointer;
  display: block;
  margin-bottom: 0.5em;
  padding: 0.075em 0 0 2em;
  position: relative;
  user-select: none;

  &:hover {
    > .radio__input {
      ~ .radio__radio {
        background-color: #ccc;
      }

      &:checked {
        ~ .radio__radio {
          background-color: var(--toggle-checked-track-color);
        }
      }
    }
  }
}

.radio--small {
  font-size: 0.875rem;
}

.radio--normal {
  font-size: 1rem;
}

.radio__input {
  cursor: pointer;
  opacity: 0;
  position: absolute;

  &:checked {
    ~ .radio__radio {
      background-color: var(--toggle-checked-track-color);

      &::after {
        display: block;
      }
    }
  }
}

.radio__radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;

  &::after {
    background: white;
    border-radius: 50%;
    content: "";
    display: none;
    height: 8px;
    left: 9px;
    position: absolute;
    top: 9px;
    width: 8px;
  }
}

.radio__radio--small {
  height: 15px;
  width: 15px;

  &::after {
    height: 5px;
    left: 5px;
    top: 5px;
    width: 5px;
  }
}

.radio__radio--normal {
  height: 18px;
  width: 18px;

  &::after {
    height: 6px;
    left: 6px;
    top: 6px;
    width: 6px;
  }
}
