.badge {
  display: inline-block;
  position: relative;
}

.badge__badge {
  align-items: center;
  background-color: var(--colors-grey);
  border-radius: 10px;
  color: var(--colors-white);
  display: flex;
  font-size: 0.75rem;
  justify-content: center;
  height: 18px;
  padding: 0 6px;
  position: absolute;
  right: -12px;
  top: -10px;
}
