.checkbox {
  cursor: pointer;
  //display: block;
  //margin-bottom: 0.5em;
  padding: 0.075em 0 0 1.65em;
  position: relative;
  user-select: none;

  .checkbox__label {
    position: relative;
    top: -0.12em;
  }

  &.disabled {
    color: var(--colors-grey);
    cursor: not-allowed;

    .checkbox__checkbox {
      &:after {
        border-color: var(--colors-grey);
      }
    }
  }
}

.checkbox--small {
  font-size: 0.875rem;
}

.checkbox--normal {
  font-size: 1rem;
}

/* Hide the browser's default radio button */
.checkbox__input {
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;

  &:checked {
    ~ .checkbox__checkbox {
      border-color: var(--toggle-checked-track-color);
      background-color: var(--toggle-checked-track-color);

      &:after {
        content: "";
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}

.checkbox__checkbox {
  border: 2px solid #3168a8;
  border-radius: 3px;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;

  &:after {
    border: solid var(--colors-white);
    border-width: 0 3px 3px 0;
    height: 14px;
    left: 4px;
    position: absolute;
    width: 8px;
  }
}

.checkbox__checkbox--normal {
  height: 18px;
  width: 18px;

  &:after {
    border-width: 0 2px 2px 0;
    height: 12px;
    left: 4px;
    top: 0;
    width: 7px;
  }
}

.checkbox__checkbox--small {
  height: 15px;
  width: 15px;

  &:after {
    border-width: 0 2px 2px 0;
    height: 10px;
    left: 3px;
    top: 0;
    width: 6px;
  }
}
