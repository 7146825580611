.nav-bar {
  align-items: center;
  background-color: var(--colors-primary);
  box-shadow: 0 0 4px var(--colors-grey);
  display: flex;
  justify-content: space-between;
  padding: 0.25em 0.5em;
  position: relative;
  width: 100vw;

  @media (min-width: $screen-lg) {
    justify-content: flex-start;
    padding: 0 1em;
  }
}

.nav-bar__items {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-bar__items--desktop {
  display: none;

  @media (min-width: $screen-lg) {
    display: flex;
  }
}

.nav-bar__items--mobile {
  display: flex;
  flex-direction: column;
  padding-left: 1em;

  > .nav-bar__item {
    > .nav-bar__link {
      color: var(--colors-black);
    }

    > .nav-bar__sub-item {
      box-shadow: initial;
      display: block;
      padding-left: 1em;
      position: initial;
      width: auto;
      z-index: $layer-0;
    }
  }

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.nav-bar__menu {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: $layer-3;

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.nav-bar__menu--expanded {
  > .nav-bar__menu-icon {
    &:before {
      background-color: var(--colors-black);
      transform: translateY(8px) rotate(135deg);
    }

    &:after {
      background-color: var(--colors-black);
      transform: translateY(-8px) rotate(-135deg);
    }

    > div {
      transform: scale(0);
    }
  }
}

.nav-bar__menu-icon {
  width: 22px;

  &:before,
  &:after,
  > div {
    background-color: var(--colors-white);
    border-radius: 3px;
    content: '';
    display: block;
    height: 3px;
    margin: 5px 0;
    transition: all 0.2s ease-in-out;
  }

  &:before {
    transform: translateY(0) rotate(0deg);
  }

  &:after {
    transform: translateY(0) rotate(0deg);
  }

  > div {
    transform: scale(1);
  }
}

.nav-bar__brand {
  color: var(--colors-white);
  font-size: 2.5rem;
}

.nav-bar__spacer {
  height: 34px;
  width: 34px;

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.nav-bar__item {
  position: relative;

  &:hover {
    > .nav-bar__caret {
      display: inline-block;
    }

    > .nav-bar__sub-item {
      display: block;
    }
  }
}

.nav-bar__link {
  color: var(--colors-white);
  display: inline-block;
  padding: 1em;
  position: relative;
  text-decoration: none;
}

.nav-bar__subnav-indicator {
  @extend .caret;
  @extend .caret--down;

  border-top-color: var(--colors-white);
  position: absolute;
  right: -3px;
  top: 22px;
}

.nav-bar__caret {
  border: 10px solid var(--colors-white);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  bottom: 0;
  display: none;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: $layer-3;
}

$hover: var(--colors-primary);

.nav-bar__sub-item {
  background-color: var(--colors-white);
  box-shadow: 0 0 4px var(--colors-grey);
  display: none;
  position: absolute;
  top: 100%;
  width: 12em;
  z-index: $layer-2;

  > .nav-bar__item {
    > .nav-bar__link {
      color: var(--colors-black);
    }
  }
}

.nav-bar__mobile-nav {
  @extend .custom-scrollbar;

  border-top: 1px solid var(--colors-grey-light);
  margin-top: 2.875em;
  width: 360px;

  @media (min-width: $screen-lg) {
    display: none;
  }
}
