.pagination {
  align-items: center;
  display: flex;
  gap: 5em;
  justify-content: space-between;
  padding-left: 1em;
  width: max-content;
}

.pagination__left-side {
  align-items: center;
  display: flex;
}
.pagination__right-side {
  align-items: center;
  display: flex;
  gap: 0.2em;
}


.pagination__items-display-area {
  border-right: 1px solid #c8d6eb;
  padding-right: 0.7em;
}
.pagination__per-page-area {
  align-items: center;
  display: flex;
  padding-left: 0.7em;
}
.pagination__page-button.cleanbutton {
  border: 1px solid #7d8ea7;
  border-radius: 12px;
  height: 32px;
  width: 32px;
}
.pagination__page-button.cleanbutton:disabled {
  border: 1px solid rgba(16, 40, 72, 0.09);
  background-color: #f5f6f8;
  cursor: default;
}
.pagination__page-button.cleanbutton:disabled>i {
  color: #5c6b82;
}





.pagination__dropdown.dropdown {
  padding-inline: 0.5em;
  width: max-content;
}
.pagination__dropdown .dropdown__dropdown {
  border: none;
  gap: 0.5em;
  min-width: unset;
}
.pagination__dropdown .dropdown__label {
  font-weight: bold;
  text-decoration: underline;
}
.pagination__dropdown .dropdown__items {
  top: unset;
  bottom: 100%;
}


