/* Package stylesheets */
/* Prerequisites */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
* {
  box-sizing: border-box;
}
*:before {
  box-sizing: border-box;
}
*:after {
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  scroll-behavior: smooth;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}
blockquote:before, q:before {
  content: "";
  content: none;
}
blockquote:after, q:after {
  content: "";
  content: none;
}

table {
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

html, body, #main {
  height: 100%;
}

body, button {
  font-display: swap;
  font-family: "Poppins", "Arial", "Helvetica";
}

body:before {
  content: "mobile";
  display: none;
}
@media (min-width: 992px) {
  body:before {
    content: "tablet";
  }
}
@media (min-width: 1200px) {
  body:before {
    content: "desktop";
  }
}

:root {
  --colors-primary: #2196F3;
  --colors-white: #fff;
  --colors-black: #000;
  --colors-error: #ff0000;
  --colors-green: green;
  --colors-grey: #979797;
  --colors-grey-light: #ccc;
  --toast-background-color: #323232;
  --toggle-checked-track-color: #3168a8;
  --toggle-unchecked-track-color: #999;
  --range-slider-track-color: #e9e9e9;
}

.caret, .nav-bar__subnav-indicator {
  border: 6px solid var(--colors-black);
  display: inline-block;
  height: 0;
  width: 0;
}

.caret--up {
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
}

.caret--right {
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-left-color: transparent;
}

.caret--down, .nav-bar__subnav-indicator {
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.caret--left {
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
}

.chevron, .calendar__next-icon, .calendar__prev-icon, .slider__move-icon--next, .slider__move-icon--prev, .pagination-old__move-icon--next, .pagination-old__move-icon--prev, .accordion__header-icon {
  display: inline-block;
  height: 12px;
  position: relative;
  width: 12px;
}
.chevron::after, .calendar__next-icon::after, .calendar__prev-icon::after, .slider__move-icon--next::after, .slider__move-icon--prev::after, .pagination-old__move-icon--next::after, .pagination-old__move-icon--prev::after, .accordion__header-icon::after {
  border-bottom: 2px solid;
  border-right: 2px solid;
  content: "";
  height: 10px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  width: 10px;
}

.chevron--up {
  transform: rotate(-90deg);
}

.chevron--right, .calendar__next-icon, .slider__move-icon--next, .pagination-old__move-icon--next {
  transform: rotate(-45deg);
}

.chevron--down, .accordion__header-icon {
  transform: rotate(45deg);
}

.chevron--left, .calendar__prev-icon, .slider__move-icon--prev, .pagination-old__move-icon--prev {
  transform: rotate(135deg);
}

.cross, .error-modal__icon {
  display: inline-block;
  height: 14px;
  width: 18px;
}
.cross:before, .error-modal__icon:before, .cross:after, .error-modal__icon:after {
  background-color: var(--colors-black);
  border-radius: 3px;
  content: "";
  display: block;
  height: 3px;
  margin: 5px 0;
}
.cross:before, .error-modal__icon:before {
  transform: rotate(135deg);
}
.cross:after, .error-modal__icon:after {
  transform: translateY(-8px) rotate(-135deg);
}

.minus {
  display: inline-block;
  position: relative;
}
.minus::after {
  background: var(--colors-black);
  content: "";
  height: 2px;
  left: -5px;
  position: absolute;
  top: 5px;
  width: 12px;
}

.plus {
  background: var(--colors-black);
  display: inline-block;
  height: 12px;
  position: relative;
  width: 2px;
}
.plus::after {
  background: var(--colors-black);
  content: "";
  height: 2px;
  left: -5px;
  position: absolute;
  top: 5px;
  width: 12px;
}

.circle, .glossary__term, .avatar {
  background-color: var(--colors-primary);
  border-radius: 50%;
  display: inline-block;
  height: 50px;
  width: 50px;
}

.square {
  background-color: var(--colors-primary);
  display: inline-block;
  height: 50px;
  width: 50px;
}

input[type=text],
select {
  border: 1px solid var(--colors-grey-light);
  border-radius: 0.25em;
  font-size: 1rem;
  outline: none;
  padding: 0.5em;
}
input[type=text]:focus,
select:focus {
  border-color: var(--colors-primary);
}

.button, .error-modal__ok, .file-upload__file-upload {
  background-color: var(--colors-primary);
  background-position: center;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0 0 4px var(--colors-grey);
  color: var(--colors-white);
  font-size: 16px;
  outline: none;
  padding: 10px 16px;
  text-transform: uppercase;
  transition: background 0.8s;
}
.button:hover, .error-modal__ok:hover, .file-upload__file-upload:hover {
  background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
}
.button:active, .error-modal__ok:active, .file-upload__file-upload:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}

.button--secondary {
  background-color: var(--colors-white);
  color: var(--colors-black);
}
.button--secondary:hover {
  background: rgba(0, 0, 0, 0.04) radial-gradient(circle, transparent 1%, rgba(0, 0, 0, 0.04) 1%) center/15000%;
}
.button--secondary:active {
  background-color: rgba(0, 0, 0, 0.04);
  background-size: 100%;
  transition: background 0s;
}

.custom-scrollbar, .nav-bar__mobile-nav, .dropdown__items {
  overflow-y: auto;
  scrollbar-color: #888;
  scrollbar-width: thin;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.custom-scrollbar::-webkit-scrollbar, .nav-bar__mobile-nav::-webkit-scrollbar, .dropdown__items::-webkit-scrollbar {
  width: 5px;
}
.custom-scrollbar::-webkit-scrollbar-track, .nav-bar__mobile-nav::-webkit-scrollbar-track, .dropdown__items::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.custom-scrollbar::-webkit-scrollbar-thumb, .nav-bar__mobile-nav::-webkit-scrollbar-thumb, .dropdown__items::-webkit-scrollbar-thumb {
  background: #888;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover, .nav-bar__mobile-nav::-webkit-scrollbar-thumb:hover, .dropdown__items::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-scrollbar--hidden {
  scrollbar-width: none;
}
.custom-scrollbar--hidden::-webkit-scrollbar {
  display: none;
}

.checkbox {
  cursor: pointer;
  padding: 0.075em 0 0 1.65em;
  position: relative;
  user-select: none;
}
.checkbox .checkbox__label {
  position: relative;
  top: -0.12em;
}
.checkbox.disabled {
  color: var(--colors-grey);
  cursor: not-allowed;
}
.checkbox.disabled .checkbox__checkbox:after {
  border-color: var(--colors-grey);
}

.checkbox--small {
  font-size: 0.875rem;
}

.checkbox--normal {
  font-size: 1rem;
}

/* Hide the browser's default radio button */
.checkbox__input {
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}
.checkbox__input:checked ~ .checkbox__checkbox {
  border-color: var(--toggle-checked-track-color);
  background-color: var(--toggle-checked-track-color);
}
.checkbox__input:checked ~ .checkbox__checkbox:after {
  content: "";
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox__checkbox {
  border: 2px solid #3168a8;
  border-radius: 3px;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}
.checkbox__checkbox:after {
  border: solid var(--colors-white);
  border-width: 0 3px 3px 0;
  height: 14px;
  left: 4px;
  position: absolute;
  width: 8px;
}

.checkbox__checkbox--normal {
  height: 18px;
  width: 18px;
}
.checkbox__checkbox--normal:after {
  border-width: 0 2px 2px 0;
  height: 12px;
  left: 4px;
  top: 0;
  width: 7px;
}

.checkbox__checkbox--small {
  height: 15px;
  width: 15px;
}
.checkbox__checkbox--small:after {
  border-width: 0 2px 2px 0;
  height: 10px;
  left: 3px;
  top: 0;
  width: 6px;
}

.dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.dropdown__dropdown {
  align-items: center;
  background: none;
  border: 1px solid #8396b4;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  font-size: 0.9rem;
  height: 48px;
  justify-content: space-between;
  min-width: 300px;
  outline: none;
  padding: 0 10px;
  position: relative;
}

.dropdown__legend {
  background-color: white;
  font-size: 0.9em;
  padding: 0 0.1em;
  position: absolute;
  top: -0.85em;
  z-index: 1;
}

.dropdown__dropdown--open .dropdown__caret-cont {
  transform: rotate(180deg);
  transition: transform 0.2s;
}

.dropdown__label {
  font-weight: 500;
}

.dropdown__label--placeholder {
  color: var(--colors-grey);
}

.dropdown__caret-cont {
  transition: transform 0.2s;
}

.dropdown__arrow {
  border: 6px solid rgb(128, 128, 128);
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  display: inline-block;
  height: 0;
  margin: 8px 0 0 10px;
  width: 0;
}

.dropdown__arrow--open {
  border-top-color: transparent;
  border-bottom-color: rgb(128, 128, 128);
  margin-top: -5px;
}

.dropdown__items {
  background-color: var(--colors-white);
  border-radius: 4px;
  border: 1px solid var(--colors-grey-light);
  display: none;
  list-style-type: none;
  margin: 3px 0 0;
  max-height: 300px;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 999;
}

.dropdown__items--open {
  display: block;
}

.dropdown__item {
  background-color: var(--colors-white);
  display: flex;
  border: none;
  cursor: pointer;
  justify-content: space-between;
  font-size: 0.9rem;
  outline: none;
  padding: 1.1em 0.8em;
  width: 100%;
}
.dropdown__item:focus, .dropdown__item:hover {
  background-color: rgb(250, 250, 250);
}

.dropdown__item--selected {
  background-color: rgb(238, 238, 238);
}
.dropdown__item--selected > .dropdown__item-checked {
  display: initial;
}

.dropdown__item-checked {
  display: none;
  padding-right: 0.8em;
  position: relative;
}
.dropdown__item-checked:after {
  content: "";
  border: solid var(--colors-green);
  border-width: 0 2px 2px 0;
  height: 14px;
  position: absolute;
  transform: rotate(45deg);
  width: 6px;
}

.toggle {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 20px;
  padding: 0.1em 0 0 3em;
  position: relative;
}

.toggle__input {
  height: 0;
  opacity: 0;
  position: relative;
  width: 0;
}
.toggle__input:focus {
  box-shadow: 0 0 1px var(--toggle-checked-track-color);
}
.toggle__input:checked + .toggle__track {
  background-color: var(--toggle-checked-track-color);
}
.toggle__input:checked + .toggle__track:before {
  transform: translateX(-16px);
}

.toggle__track {
  background-color: var(--toggle-unchecked-track-color);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
  width: 36px;
}
.toggle__track:before {
  background-color: white;
  bottom: 2px;
  content: "";
  height: 16px;
  right: 2px;
  position: absolute;
  transition: 0.4s;
  width: 16px;
}

.toggle__track--round {
  border-radius: 34px;
}
.toggle__track--round:before {
  border-radius: 50%;
}

.radio {
  cursor: pointer;
  display: block;
  margin-bottom: 0.5em;
  padding: 0.075em 0 0 2em;
  position: relative;
  user-select: none;
}
.radio:hover > .radio__input ~ .radio__radio {
  background-color: #ccc;
}
.radio:hover > .radio__input:checked ~ .radio__radio {
  background-color: var(--toggle-checked-track-color);
}

.radio--small {
  font-size: 0.875rem;
}

.radio--normal {
  font-size: 1rem;
}

.radio__input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}
.radio__input:checked ~ .radio__radio {
  background-color: var(--toggle-checked-track-color);
}
.radio__input:checked ~ .radio__radio::after {
  display: block;
}

.radio__radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}
.radio__radio::after {
  background: white;
  border-radius: 50%;
  content: "";
  display: none;
  height: 8px;
  left: 9px;
  position: absolute;
  top: 9px;
  width: 8px;
}

.radio__radio--small {
  height: 15px;
  width: 15px;
}
.radio__radio--small::after {
  height: 5px;
  left: 5px;
  top: 5px;
  width: 5px;
}

.radio__radio--normal {
  height: 18px;
  width: 18px;
}
.radio__radio--normal::after {
  height: 6px;
  left: 6px;
  top: 6px;
  width: 6px;
}

.range-slider {
  width: 100%;
}

.range-slider__slider {
  appearance: none;
  -moz-apperance: none;
  -webkit-appearance: none;
  border-radius: 5px;
  height: 4px;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0.15, var(--colors-primary)), color-stop(0.15, var(--colors-primary)));
  outline: none;
  width: 100%;
}
.range-slider__slider::-moz-range-thumb {
  appearance: none;
  -moz-appearance: none;
  background-color: var(--colors-white);
  border: 2px solid var(--colors-primary);
  border-radius: 50%;
  cursor: grab;
  height: 14px;
  width: 14px;
}
.range-slider__slider::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  background-color: var(--colors-white);
  border: 2px solid var(--colors-primary);
  border-radius: 50%;
  cursor: grab;
  height: 14px;
  width: 14px;
}

.range-slider__value {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5em;
}

.file-upload {
  margin: 1em 0;
  position: relative;
}

.file-upload__input {
  cursor: pointer;
  height: 40px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 5.75%;
}

.file-upload__files {
  padding-left: 1em;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.45s, visibility 0.5s;
  visibility: hidden;
  z-index: 10000;
}

.overlay--visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.45s;
}

.drawer {
  background-color: var(--colors-white);
  display: flex;
  flex-direction: column;
  position: fixed;
  transition: transform 255ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 999;
}

.drawer--visible {
  transform: none !important;
}

.drawer--anchor-top {
  top: 0;
  transform: translateY(-100%);
  width: 100%;
}

.drawer--anchor-right {
  height: 100%;
  right: 0;
  top: 0;
  transform: translateX(100%);
}

.drawer--anchor-bottom {
  bottom: 0;
  transform: translateY(100%);
  width: 100%;
}

.drawer--anchor-left {
  height: 100%;
  left: 0;
  top: 0;
  transform: translateX(-100%);
}

.accordion {
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.accordion__panel {
  border-bottom: 1px solid var(--colors-grey-light);
  overflow: hidden;
  position: relative;
  width: 100%;
}
.accordion__panel:last-child {
  border-bottom: none;
}

.accordion__checkbox {
  cursor: pointer;
  height: 3.5em;
  margin: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
}
.accordion__checkbox:checked ~ .accordion__header .accordion__header-icon:after {
  transform: rotate(-180deg);
}
.accordion__checkbox:checked ~ .accordion__content {
  max-height: 100vh;
  transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.accordion__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1em 2em 1em 1.2em;
}

.accordion__header-title {
  margin: 0.8em 0;
}

.accordion__header-icon:after {
  border-bottom-width: 3px;
  border-right-width: 3px;
  transition: transform 300ms;
}

.accordion__content {
  max-height: 0;
  padding: 0 1.2em;
}

.tooltip {
  display: inline-block;
  position: relative;
}

.tooltip__container:hover ~ .tooltip__content {
  opacity: 100;
  transition: opacity z-index 0.25s ease-in;
  z-index: 1;
}

.tooltip__content {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  color: #fff;
  cursor: default;
  max-width: 300px;
  opacity: 0;
  padding: 5px 8px;
  position: absolute;
  text-align: center;
  transition: opacity 0.25s ease-out;
  white-space: nowrap;
  z-index: -1;
}
.tooltip__content:after {
  content: "";
  border-width: 5px;
  border-style: solid;
  left: 50%;
  margin-left: -5px;
  position: absolute;
}

.tooltip__content--visible {
  opacity: 100;
  transition: opacity z-index 0.25s ease-in;
  z-index: 1;
}

.tooltip__content--top:after {
  border-color: rgba(0, 0, 0, 0.5) transparent transparent transparent;
  top: 100%;
}

.tooltip__content--right:after {
  border-color: transparent rgba(0, 0, 0, 0.5) transparent transparent;
  margin: -5px 0 0;
  left: -10px;
  top: 50%;
}

.tooltip__content--bottom {
  top: 150%;
}
.tooltip__content--bottom:after {
  bottom: 100%;
  border-color: transparent transparent rgba(0, 0, 0, 0.5) transparent;
}

.tooltip__content--left:after {
  bottom: 100%;
  border-color: transparent transparent transparent rgba(0, 0, 0, 0.5);
  left: 100%;
  margin: -5px 0 0;
  top: 50%;
}

.modal {
  border-radius: 5px;
  background-color: var(--colors-white);
  left: 50%;
  max-height: calc(100% - 64px);
  max-width: 600px;
  opacity: 1;
  position: absolute;
  transform: translate(-50%, 0);
  transition: transform 0.3s ease-out;
  width: 100%;
}

.modal--visible {
  opacity: 1;
  transform: translate(-50%, 25%);
  transition: transform 0.3s ease-out;
}

.toast {
  background-color: var(--toast-background-color);
  border-radius: 4px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.5), 0px 6px 10px 0px rgba(0, 0, 0, 0.4), 0px 1px 18px 0px rgba(0, 0, 0, 0.2);
  color: var(--colors-white);
  display: flex;
  min-width: 280px;
  opacity: 0;
  padding: 1em;
  position: fixed;
  transition: opacity 0.25s ease-out;
  z-index: 999;
}

.toast--visible {
  opacity: 100;
  transition: opacity 0.25s ease-in;
}

.toast--bottom-right {
  bottom: 24px;
  right: 24px;
}

.toast--bottom-middle {
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.toast--bottom-left {
  bottom: 24px;
  left: 24px;
}

.toast--left-middle {
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.toast--right-middle {
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.toast--top-left {
  left: 24px;
  top: 24px;
}

.toast--top-middle {
  left: 50%;
  top: 24px;
  transform: translateX(-50%);
}

.toast--top-right {
  right: 24px;
  top: 24px;
}

.badge {
  display: inline-block;
  position: relative;
}

.badge__badge {
  align-items: center;
  background-color: var(--colors-grey);
  border-radius: 10px;
  color: var(--colors-white);
  display: flex;
  font-size: 0.75rem;
  justify-content: center;
  height: 18px;
  padding: 0 6px;
  position: absolute;
  right: -12px;
  top: -10px;
}

.breadcrumb {
  display: flex;
  margin: 0;
}

.breadcrumb__item {
  align-items: center;
  display: flex;
}
.breadcrumb__item:not(:first-child)::before {
  content: "/";
  padding: 0 0.5em;
}
.breadcrumb__item > a {
  color: var(--colors-primary);
  text-decoration: none;
}

.avatar {
  align-items: center;
  background-color: var(--colors-grey);
  color: var(--colors-white);
  display: flex;
  justify-content: center;
  user-select: none;
}

.avatar--small {
  height: 30px;
  font-size: 0.8rem;
  width: 30px;
}

.avatar--normal {
  height: 50px;
  font-size: 1.2rem;
  width: 50px;
}

.avatar--large {
  height: 70px;
  font-size: 2rem;
  width: 70px;
}

.avatar__avatar {
  border-radius: 50%;
  width: 100%;
}

.progress-bar {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.progress-bar__step {
  flex: 1 1 0;
}
.progress-bar__step:last-child {
  flex-grow: 0;
}

.progress-bar__step--complete > .progress-bar__track:after {
  background-color: var(--colors-grey);
  background-position: -100% 100%;
}

.progress-bar__track {
  display: block;
  position: relative;
}
.progress-bar__track:before {
  align-items: center;
  background-color: var(--colors-primary);
  border-radius: 50%;
  color: var(--colors-white);
  content: attr(data-value);
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}
.progress-bar__track:after {
  background: linear-gradient(to right, var(--colors-grey-light) 50%, var(--colors-grey) 50%);
  background-color: var(--colors-grey-light);
  background-position: 0% 100%;
  background-size: 200% 100%;
  content: "";
  display: block;
  height: 4px;
  position: absolute;
  top: 10px;
  transition: all 1s ease;
  width: 100%;
  z-index: -1;
}

.progress-ring__percent {
  text-anchor: middle;
}

.progress-ring__percent--xsmall {
  font-size: 0.5rem;
}

.progress-ring__percent--small {
  font-size: 0.8rem;
}

.progress-ring__percent--medium {
  font-size: 1rem;
}

.progress-ring__percent--normal {
  font-size: 1.2rem;
}

.progress-ring__percent--large {
  font-size: 1.6rem;
}

.progress-ring__percent--xlarge {
  font-size: 1.8rem;
}

.progress-ring__ring {
  stroke-dasharray: 0 0;
  stroke-dashoffset: 0;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 1s;
}

.rating {
  display: inline-block;
}

.rating__label {
  text-align: center;
}

.rating__ratings {
  display: inline-flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* POSSIBLY REFACTOR TO SHARE SELECTORS */
.rating__item:hover > .rating__icon-cont > .rating__icon {
  display: none;
}
.rating__item:hover > .rating__icon-cont > .rating__icon--filled {
  display: inline;
}
.rating__item:hover ~ .rating__item > .rating__icon-cont > .rating__icon {
  display: none;
}
.rating__item:hover ~ .rating__item > .rating__icon-cont > .rating__icon--filled {
  display: inline;
}

.rating__item--selected > .rating__icon-cont > .rating__icon--empty,
.rating__item--active > .rating__icon-cont > .rating__icon--empty {
  display: none;
}
.rating__item--selected > .rating__icon-cont > .rating__icon--partial,
.rating__item--active > .rating__icon-cont > .rating__icon--partial {
  display: none;
}
.rating__item--selected > .rating__icon-cont > .rating__icon--filled,
.rating__item--active > .rating__icon-cont > .rating__icon--filled {
  display: inline;
}
.rating__item--selected ~ .rating__item > .rating__icon-cont > .rating__icon--empty,
.rating__item--active ~ .rating__item > .rating__icon-cont > .rating__icon--empty {
  display: none;
}
.rating__item--selected ~ .rating__item > .rating__icon-cont > .rating__icon--partial,
.rating__item--active ~ .rating__item > .rating__icon-cont > .rating__icon--partial {
  display: none;
}
.rating__item--selected ~ .rating__item > .rating__icon-cont > .rating__icon--filled,
.rating__item--active ~ .rating__item > .rating__icon-cont > .rating__icon--filled {
  display: inline;
}

.rating__item--partial > .rating__icon-cont > .rating__icon--empty {
  display: none;
}
.rating__item--partial > .rating__icon-cont > .rating__icon--partial {
  display: inline;
}
.rating__item--partial > .rating__icon-cont > .rating__icon--filled {
  display: none;
}

.rating__item--disabled {
  pointer-events: none;
}

.rating__icon-cont {
  align-items: center;
  display: flex;
  padding: 0.2em 0.1em;
  cursor: pointer;
}
.rating__icon-cont:focus {
  outline: none;
}

.rating__icon {
  color: var(--colors-primary);
  font-size: 1.225rem;
  margin: 0 0.1em;
  pointer-events: none;
}

.rating__icon--partial {
  display: none;
}

.rating__icon--filled {
  display: none;
}

.rating__scale {
  display: flex;
  justify-content: space-between;
  padding: 0 2px;
}

.nav-bar {
  align-items: center;
  background-color: var(--colors-primary);
  box-shadow: 0 0 4px var(--colors-grey);
  display: flex;
  justify-content: space-between;
  padding: 0.25em 0.5em;
  position: relative;
  width: 100vw;
}
@media (min-width: 992px) {
  .nav-bar {
    justify-content: flex-start;
    padding: 0 1em;
  }
}

.nav-bar__items {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-bar__items--desktop {
  display: none;
}
@media (min-width: 992px) {
  .nav-bar__items--desktop {
    display: flex;
  }
}

.nav-bar__items--mobile {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
}
.nav-bar__items--mobile > .nav-bar__item > .nav-bar__link {
  color: var(--colors-black);
}
.nav-bar__items--mobile > .nav-bar__item > .nav-bar__sub-item {
  box-shadow: initial;
  display: block;
  padding-left: 1em;
  position: initial;
  width: auto;
  z-index: 0;
}
@media (min-width: 992px) {
  .nav-bar__items--mobile {
    display: none;
  }
}

.nav-bar__menu {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 1000;
}
@media (min-width: 992px) {
  .nav-bar__menu {
    display: none;
  }
}

.nav-bar__menu--expanded > .nav-bar__menu-icon:before {
  background-color: var(--colors-black);
  transform: translateY(8px) rotate(135deg);
}
.nav-bar__menu--expanded > .nav-bar__menu-icon:after {
  background-color: var(--colors-black);
  transform: translateY(-8px) rotate(-135deg);
}
.nav-bar__menu--expanded > .nav-bar__menu-icon > div {
  transform: scale(0);
}

.nav-bar__menu-icon {
  width: 22px;
}
.nav-bar__menu-icon:before, .nav-bar__menu-icon:after,
.nav-bar__menu-icon > div {
  background-color: var(--colors-white);
  border-radius: 3px;
  content: "";
  display: block;
  height: 3px;
  margin: 5px 0;
  transition: all 0.2s ease-in-out;
}
.nav-bar__menu-icon:before {
  transform: translateY(0) rotate(0deg);
}
.nav-bar__menu-icon:after {
  transform: translateY(0) rotate(0deg);
}
.nav-bar__menu-icon > div {
  transform: scale(1);
}

.nav-bar__brand {
  color: var(--colors-white);
  font-size: 2.5rem;
}

.nav-bar__spacer {
  height: 34px;
  width: 34px;
}
@media (min-width: 992px) {
  .nav-bar__spacer {
    display: none;
  }
}

.nav-bar__item {
  position: relative;
}
.nav-bar__item:hover > .nav-bar__caret {
  display: inline-block;
}
.nav-bar__item:hover > .nav-bar__sub-item {
  display: block;
}

.nav-bar__link {
  color: var(--colors-white);
  display: inline-block;
  padding: 1em;
  position: relative;
  text-decoration: none;
}

.nav-bar__subnav-indicator {
  border-top-color: var(--colors-white);
  position: absolute;
  right: -3px;
  top: 22px;
}

.nav-bar__caret {
  border: 10px solid var(--colors-white);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  bottom: 0;
  display: none;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 1000;
}

.nav-bar__sub-item {
  background-color: var(--colors-white);
  box-shadow: 0 0 4px var(--colors-grey);
  display: none;
  position: absolute;
  top: 100%;
  width: 12em;
  z-index: 999;
}
.nav-bar__sub-item > .nav-bar__item > .nav-bar__link {
  color: var(--colors-black);
}

.nav-bar__mobile-nav {
  border-top: 1px solid var(--colors-grey-light);
  margin-top: 2.875em;
  width: 360px;
}
@media (min-width: 992px) {
  .nav-bar__mobile-nav {
    display: none;
  }
}

.pagination-old {
  align-items: center;
  display: flex;
}

.pagination-old__move {
  background: none;
  border: none;
  color: var(--colors-grey-light);
  cursor: pointer;
  font-size: 0.9rem;
  outline: none;
}

.pagination-old__move--label {
  border: 1px solid var(--colors-grey);
  border-radius: 4px;
  color: var(--toggle-checked-track-color);
  padding: 4px 8px;
}

.pagination-old__move--prev {
  margin-right: 8px;
}

.pagination-old__move--next {
  margin-left: 8px;
}

.pagination-old__move--first {
  margin-right: 8px;
}

.pagination-old__move--last {
  margin-left: 8px;
}

.pagination-old__move-icon:after {
  border-bottom-width: 3px;
  border-right-width: 3px;
}

.pagination-old__pages {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.pagination-old__page {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 4px 8px;
  outline: none;
}

.pagination-old__page--active {
  background-color: var(--toggle-checked-track-color);
  border-radius: 4px;
  color: var(--colors-white);
}

.tab {
  display: flex;
  flex-direction: column;
}

.tab__tabs {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.tab__tab--full-width {
  width: 100%;
}

.tab__tab .tab__panel {
  background: none;
  border: 1px solid transparent;
  cursor: pointer;
  margin-bottom: -1px;
  padding: 0.5em 1em;
  position: relative;
  outline: none;
  font-size: 0.875em;
  font-weight: 500;
  color: #20233d;
  height: 3.5em; /* 56px */
}
.tab__tab .tab__panel--active {
  border-bottom: solid 2px #20233d;
  padding-bottom: calc(0.5em - 2px);
}
.tab__tab .tab__panel--full-width {
  width: 100%;
}

.tab__content {
  display: none;
  top: 100%;
}

.tab__content--active {
  display: block;
}

.shimmer {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 1200px 100%;
  margin: 0.5em 0 0;
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
.shimmer--line {
  border-radius: 20px;
}

.shimmer--round {
  border-radius: 50%;
}
.shimmer--round.shimmer--small {
  height: 40px;
  width: 40px;
}
.shimmer--round.shimmer--normal {
  height: 60px;
  width: 60px;
}
.shimmer--round.shimmer--large {
  height: 80px;
  width: 80px;
}

.shimmer--square {
  border-radius: 8px;
  height: 100%;
  width: 100%;
}

.shimmer--small {
  height: 8px;
}

.shimmer--normal {
  height: 14px;
}

.shimmer--large {
  height: 24px;
}

.shimmer--w25 {
  width: 25%;
}

.shimmer--w50 {
  width: 50%;
}

.shimmer--w75 {
  width: 75%;
}

.shimmer--w100 {
  width: 100%;
}

.slider {
  position: relative;
}

.slider__prev {
  align-items: center;
  background-color: var(--colors-grey-light);
  border: none;
  border-radius: 50%;
  color: var(--colors-white);
  cursor: pointer;
  display: flex;
  height: 20px;
  left: -30px;
  outline: none;
  opacity: 0.5;
  padding-left: 6px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
}
.slider__prev:hover {
  opacity: 1;
}

.slider__move-icon:after {
  border-bottom-width: 3px;
  border-right-width: 3px;
}

.slider__slider {
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.slider__slide {
  scroll-snap-align: start;
}

.slider__next {
  align-items: center;
  background-color: var(--colors-grey-light);
  border: none;
  border-radius: 50%;
  color: var(--colors-white);
  cursor: pointer;
  display: flex;
  height: 20px;
  outline: none;
  opacity: 0.5;
  padding-right: 6px;
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
}
.slider__next:hover {
  opacity: 1;
}

.slider__dots {
  bottom: -30px;
  display: flex;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.slider__dot {
  background-color: var(--colors-grey-light);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 14px;
  margin: 0 10px;
  outline: none;
  width: 14px;
}
.slider__dot:hover {
  background-color: var(--colors-grey);
}

.slider__dot--active {
  background-color: var(--colors-grey);
}

.bounce-loader {
  height: 60px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
}

@keyframes bounce-animation {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.bounce-loader__circle {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.bounce-loader__circle:before {
  animation: bounce-animation 1.2s infinite ease-in-out both;
  background-color: var(--colors-primary);
  border-radius: 50%;
  content: "";
  display: block;
  height: 15%;
  margin: 0 auto;
  width: 15%;
}
.bounce-loader__circle:nth-child(2) {
  transform: rotate(30deg);
}
.bounce-loader__circle:nth-child(2):before {
  animation-delay: -1.1s;
}
.bounce-loader__circle:nth-child(3) {
  transform: rotate(60deg);
}
.bounce-loader__circle:nth-child(3):before {
  animation-delay: -1s;
}
.bounce-loader__circle:nth-child(4) {
  transform: rotate(90deg);
}
.bounce-loader__circle:nth-child(4):before {
  animation-delay: -0.9s;
}
.bounce-loader__circle:nth-child(5) {
  transform: rotate(120deg);
}
.bounce-loader__circle:nth-child(5):before {
  animation-delay: -0.8s;
}
.bounce-loader__circle:nth-child(6) {
  transform: rotate(150deg);
}
.bounce-loader__circle:nth-child(6):before {
  animation-delay: -0.7s;
}
.bounce-loader__circle:nth-child(7) {
  transform: rotate(180deg);
}
.bounce-loader__circle:nth-child(7):before {
  animation-delay: -0.6s;
}
.bounce-loader__circle:nth-child(8) {
  transform: rotate(210deg);
}
.bounce-loader__circle:nth-child(8):before {
  animation-delay: -0.5s;
}
.bounce-loader__circle:nth-child(9) {
  transform: rotate(240deg);
}
.bounce-loader__circle:nth-child(9):before {
  animation-delay: -0.4s;
}
.bounce-loader__circle:nth-child(10) {
  transform: rotate(270deg);
}
.bounce-loader__circle:nth-child(10):before {
  animation-delay: -0.3s;
}
.bounce-loader__circle:nth-child(11) {
  transform: rotate(300deg);
}
.bounce-loader__circle:nth-child(11):before {
  animation-delay: -0.2s;
}
.bounce-loader__circle:nth-child(12) {
  transform: rotate(330deg);
}
.bounce-loader__circle:nth-child(12):before {
  animation-delay: -0.1s;
}

@keyframes spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner-loader {
  animation: spinner-animation 1s linear infinite;
  border: 6px solid var(--colors-white);
  border-top: 6px solid var(--colors-primary);
  border-radius: 50%;
  height: 40px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
}

.wave-loader {
  font-size: 10px;
  height: 40px;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
}

@keyframes block-delay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
.wave-loader__wave {
  animation: block-delay 1.2s infinite ease-in-out;
  background-color: var(--colors-primary);
  display: inline-block;
  height: 100%;
  margin: 0 3px 0 0;
  width: 6px;
}
.wave-loader__wave:nth-child(2) {
  animation-delay: -1.1s;
}
.wave-loader__wave:nth-child(3) {
  animation-delay: -1s;
}
.wave-loader__wave:nth-child(4) {
  animation-delay: -0.9s;
}
.wave-loader__wave:nth-child(5) {
  animation-delay: -0.8s;
  margin-right: 0;
}

.calendar-default {
  height: 80%;
  width: 100%;
}

.calendar-default__calendar {
  height: 100%;
  width: 100%;
}

.calendar-default__header {
  font-size: 0.875rem;
}

.calendar-default__body {
  font-size: 0.875rem;
}

.calendar-default__cell {
  text-align: center;
}

.calendar-default__day {
  background: none;
  border: none;
  color: var(--colors-black);
  cursor: pointer;
  height: 20px;
  display: inline-block;
  outline: none;
  padding: 0;
  text-decoration: none;
  width: 20px;
}

.calendar-default__day--current {
  background-color: var(--colors-primary);
  border-radius: 2px;
  color: var(--colors-white);
}

.calendar-default__day--out-of-month {
  color: var(--colors-grey-light);
}

.calendar-default__day--selected {
  outline: 2px solid var(--colors-green);
}

.calendar-months {
  height: 80%;
  width: 100%;
}

.calendar-months__calendar {
  height: 100%;
  width: 100%;
}

.calendar-months__header {
  font-size: 0.875rem;
}

.calendar-months__body {
  font-size: 0.875rem;
}

.calendar-months__cell {
  text-align: center;
}

.calendar-months__month {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.calendar-months__month--current {
  background-color: var(--colors-primary);
  border-radius: 2px;
  color: var(--colors-white);
}

.calendar-years {
  height: 80%;
  width: 100%;
}

.calendar-years__calendar {
  height: 100%;
  width: 100%;
}

.calendar-years__header {
  font-size: 0.875rem;
}

.calendar-years__body {
  font-size: 0.875rem;
}

.calendar-years__cell {
  text-align: center;
}

.calendar-years__year {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.calendar-years__year--current {
  background-color: var(--colors-primary);
  border-radius: 2px;
  color: var(--colors-white);
}

.calendar {
  background: var(--colors-white);
  border: 1px solid var(--colors-grey);
  border-radius: 4px;
  height: 12em;
  padding: 0.5em;
  width: 15em;
}

.calendar__header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 1em;
}

.calendar__month {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

.calendar__year {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding-left: 1em;
  text-decoration: none;
}

.calendar__nav {
  display: flex;
  justify-content: flex-end;
}

.calendar__prev {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.calendar__next {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.error-modal {
  border-radius: 0 0 4px 4px;
}

.error-modal__header {
  background-color: var(--colors-error);
  border-radius: 4px 4px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 1em;
}

.error-modal__title {
  color: var(--colors-white);
}

.error-modal__close {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  outline: none;
}

.error-modal__icon:before, .error-modal__icon:after {
  background-color: var(--colors-white);
}

.error-modal__body {
  padding: 1em;
}

.error-modal__footer {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
}

.map {
  height: 100%;
  width: 100%;
}

.map__marker-icon {
  margin-top: -30px !important;
}

.glossary {
  align-items: baseline;
  display: grid;
  gap: 1em;
  grid-template-columns: min-content 1fr;
}

.glossary__term {
  align-items: center;
  color: var(--colors-white);
  display: flex;
  grid-column: 1;
  height: 30px;
  justify-content: center;
  position: sticky;
  top: 1em;
  width: 30px;
}

.glossary__description {
  border-bottom: 1px solid var(--colors-grey-light);
  grid-column: 2;
}

.glossary__title {
  font-weight: 600;
}

.glossary__definition {
  padding: 1em 0;
}

.homepage {
  background-color: #eaeaea;
  display: flex;
  justify-content: center;
  min-height: 100%;
}

.homepage__centered {
  display: flex;
  flex-direction: column;
  width: calc(100% - 2.25em);
}

.homepage__title-area {
  display: flex;
  justify-content: space-between;
  padding: 1em 2em;
}

.homepage__title-and-logo {
  display: flex;
  gap: 1em;
}

.homepage__title {
  font-size: 1.1em;
  font-weight: 500;
  color: #333;
}

.locationmapping {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1.25em; /* 20px */
  padding-bottom: 3em; /*48px */
}

.locationmapping__filter-row {
  border-bottom: 1px solid #ebeef3;
  border-top: 1px solid #ebeef3;
  display: flex;
  gap: 1em;
  justify-content: flex-start;
  padding: 1.5em 0;
}

.locationmapping__title-row {
  align-items: center;
  display: flex;
  height: 5.125em; /* 82px */
}

.locationmapping__title-container {
  align-items: center;
  border-left: 0.25em solid #20fffa;
  display: flex;
  gap: 2em;
  height: 2.375em; /* 38px */
  padding-left: 1.25em;
}

.locationmapping__title-row,
.locationmapping__controls-row-top {
  margin-left: -1.5em;
}

.locationmapping__filter-button-row {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 6.25em; /* 100px */
}

.locationmapping__title-element {
  display: inline-flex;
  gap: 0.5em;
  align-items: baseline;
}

.locationmapping__title-text,
.locationmapping__title-pipe {
  color: #131245;
  font-size: 1.125em; /* 18px */
  font-weight: 500;
}

.locationmapping__controls-row-top,
.locationmapping__controls-row-bottom {
  align-items: center;
  display: flex;
}

.locationmapping__controls-row-top {
  height: 5.125em; /* 82px */
  justify-content: space-between;
  border-bottom: 1px solid #edf3fd;
}

.locationmapping__controls-row-bottom {
  height: 6.5em; /* 104px */
  justify-content: flex-end;
  gap: 1.25em; /* 20px */
}

.locationmapping__controls-row-left-side,
.locationmapping__controls-row-right-side {
  align-items: center;
  display: flex;
  gap: 1.25em; /* 20px */
}

.locationmapping__controls-row-right-side {
  align-self: flex-end;
}

.locationmapping__top-card,
.locationmapping__bottom-card {
  background-color: white;
  border-radius: 15px;
  padding: 0 1.5em; /* 56px 24px */
}

.locationmapping__bottom-card {
  flex: 1;
}

.locationmapping__center {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 2em;
}

.locationmapping__dropdown-row {
  align-items: center;
  display: flex;
  justify-content: center;
}

.locationmapping__content-area {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: max-content;
}

.locationmapping__filter-row2 {
  border: 1px solid black;
  display: flex;
  gap: 1em;
  justify-content: space-around;
  padding: 1em 0;
}

.locationmapping__mapping-type-row {
  display: flex;
  justify-content: center;
  padding: 0.5em 0;
}

.locationmapping__control,
.locationmapping__mapping-type-control {
  align-items: center;
  display: flex;
  gap: 1em;
}

.locationmapping__mapping-type-control {
  border-bottom: 2px solid black;
  padding-bottom: 0.5em;
}

.locationmapping__grid-area {
  padding-top: 1.25em;
}

.locationmapping__date-field {
  height: 48px;
}

.locationmapping__mapping-type-label {
  font-weight: bold;
}

.matchedlocationsmodal {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
}

.matchedlocationsmodal__panel {
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  height: max-content;
  width: max-content;
}

.matchedlocationsmodal__body {
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: space-evenly;
  padding: 1.25em;
}

.matchedlocationsmodal__placekey-area {
  display: flex;
  gap: 0.4em;
  justify-content: center;
}

.matchedlocationsmodal__placekey-name {
  font-weight: bold;
}

.matchedlocationsmodal__button-area {
  align-items: center;
  display: flex;
  gap: 1em;
  justify-content: flex-end;
}

.matchedlocationsmodal__title-row .matchedlocationsmodal__close-button {
  padding: 0.5em;
  font-size: 2em;
}

.matchedlocationsmodal__title-row {
  align-items: center;
  display: flex;
  height: 5.125em; /* 82px */
  justify-content: space-between;
}

.matchedlocationsmodal__title-row-side {
  align-items: center;
  display: flex;
}

.matchedlocationsmodal__title-container {
  align-items: center;
  border-left: 0.25em solid #20fffa;
  display: flex;
  height: 2.375em; /* 38px */
  padding-left: 1.25em;
}

.matchedlocationsmodal__title-text {
  color: #131245;
  font-size: 1.125em; /* 18px */
  font-weight: 500;
}

.matchedlocationsmodal__first-row-cell {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.matchedlocationsmodal__first-row-cell-header {
  font-weight: bold;
}

.matchedlocationsmodal__bottom-grid-area {
  border-top: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
  padding: 0.5em 0;
}

.maindatagrid__table {
  width: 100%;
}

.maindatagrid__table,
.maindatagrid__table th:first-child {
  border-top-left-radius: 15px;
}

.maindatagrid__table,
.maindatagrid__table th:last-child {
  border-top-right-radius: 15px;
}

.maindatagrid__table-header-cell,
.maindatagrid__table-body-cell {
  padding: 0.5em 1em;
  text-align: left;
  vertical-align: middle;
}

.maindatagrid__table-header-cell {
  font-weight: 500;
}

.maindatagrid__table-header-row {
  background-color: #edf3fd;
  height: 3.125em;
}

.maindatagrid__table-body-row {
  height: 3.5em; /* 56px */
}

.maindatagrid__table-body-row > .maindatagrid__table-body-cell {
  border-bottom: 1px solid #edf3fd;
}

.maindatagrid__pagination-container {
  padding-top: 0.2em;
}

.modaldatagrid__table {
  width: 100%;
}

.modaldatagrid__table,
.modaldatagrid__table th:first-child {
  border-top-left-radius: 15px;
}

.modaldatagrid__table,
.modaldatagrid__table th:last-child {
  border-top-right-radius: 15px;
}

.modaldatagrid__table-body-cell {
  padding: 0.5em 1em;
  text-align: left;
  vertical-align: middle;
}

.modaldatagrid__table-body-row {
  height: 3.5em; /* 56px */
}

.modaldatagrid__table-body-cell:not(:first-child) {
  border-left: 1px solid #CCC;
}

/* Rows 2 and 3 are spacer rows. They will not have side borders, but they will have
   a horizontal border between them. */
.modaldatagrid__table-body-row:nth-child(2),
.modaldatagrid__table-body-row:nth-child(3) {
  height: 0.5em;
}

.modaldatagrid__table-body-row:nth-child(2) > .modaldatagrid__table-body-cell,
.modaldatagrid__table-body-row:nth-child(3) > .modaldatagrid__table-body-cell {
  border-left: none;
}

.modaldatagrid__table-body-row > .modaldatagrid__table-body-cell:last-child {
  border-left: none;
  padding-left: 0;
}

.modaldatagrid__table-body-row > .modaldatagrid__table-body-cell:last-child .radio {
  padding: 0;
}

.modaldatagrid__table-body-row > .modaldatagrid__table-body-cell:last-child .radio__radio {
  top: -0.3em;
}

.modaldatagrid__table-body-row:nth-child(2) > .modaldatagrid__table-body-cell {
  border-bottom: 1px solid #CCC;
}

.modaldatagrid__pagination-container {
  padding-top: 0.2em;
}

.promptmodal {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9000;
  background-color: rgba(0, 0, 0, 0.6);
}

.promptmodal__panel {
  border-style: solid;
  border-width: 0.1em;
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.5em;
  padding: 1.25em;
}

.promptmodal__root .promptmodal__panel {
  min-width: 27.5em;
}

.promptmodal__buttons {
  margin-top: 2em;
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 0.5em;
}

.promptmodal__reverse-button-order {
  flex-direction: row-reverse;
  gap: 0.5em;
}

.promptmodal__buttons > button {
  border-style: solid;
  border-width: 0.1em;
  border-radius: 1000px;
  cursor: pointer;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  outline: none;
  font-size: 1.13em;
  height: 2em;
  width: 7em;
}

.promptmodal__cancel-button {
  margin-left: 2em;
}

.promptmodal__title {
  font-size: 1.7em;
  margin-top: 0;
}

.promptmodal__prompt {
  max-width: 27.5em;
  text-align: center;
}

.newtoast {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 1em;
  left: 50%;
  pointer-events: none;
  position: fixed;
  top: 3em;
  transform: translateX(-50%);
  width: 15%;
  z-index: 11000;
}

.newtoast__toast {
  border-radius: 10px;
  cursor: pointer;
  padding: 1em;
  pointer-events: auto;
  text-align: center;
  transition-property: opacity;
  width: 100%;
}

.newtoast__errortype {
  background-color: rgba(255, 0, 0, 0.3);
}

.newtoast__warningtype {
  background-color: rgba(255, 255, 0, 0.3);
}

.newtoast__open {
  opacity: 1;
}

.newtoast__closing {
  opacity: 0;
}

.pagination {
  align-items: center;
  display: flex;
  gap: 5em;
  justify-content: space-between;
  padding-left: 1em;
  width: max-content;
}

.pagination__left-side {
  align-items: center;
  display: flex;
}

.pagination__right-side {
  align-items: center;
  display: flex;
  gap: 0.2em;
}

.pagination__items-display-area {
  border-right: 1px solid #c8d6eb;
  padding-right: 0.7em;
}

.pagination__per-page-area {
  align-items: center;
  display: flex;
  padding-left: 0.7em;
}

.pagination__page-button.cleanbutton {
  border: 1px solid #7d8ea7;
  border-radius: 12px;
  height: 32px;
  width: 32px;
}

.pagination__page-button.cleanbutton:disabled {
  border: 1px solid rgba(16, 40, 72, 0.09);
  background-color: #f5f6f8;
  cursor: default;
}

.pagination__page-button.cleanbutton:disabled > i {
  color: #5c6b82;
}

.pagination__dropdown.dropdown {
  padding-inline: 0.5em;
  width: max-content;
}

.pagination__dropdown .dropdown__dropdown {
  border: none;
  gap: 0.5em;
  min-width: unset;
}

.pagination__dropdown .dropdown__label {
  font-weight: bold;
  text-decoration: underline;
}

.pagination__dropdown .dropdown__items {
  top: unset;
  bottom: 100%;
}

.buttoncontrol__dark,
.buttoncontrol__light {
  border: 1px solid #52586f;
  border-radius: 8px;
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
  height: 3em; /* 48px */
  padding: 0.75em 1.25em; /* 12px 20px */
  text-decoration: inherit;
}

.buttoncontrol__dark:disabled,
.buttoncontrol__light:disabled {
  border: none;
  color: rgba(51, 51, 51, 0.5);
  cursor: auto;
  background-color: #f5f6f8;
}

.buttoncontrol__light {
  background-color: #edf3fd;
  color: #333;
}

.buttoncontrol__dark {
  background-color: #394a7a;
  color: white;
}

.cleanbutton {
  background-color: inherit;
  border: none;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
  padding: 0;
  text-decoration: inherit;
}

.datefield {
  align-items: center;
  border: 1px solid #8396b4;
  border-radius: 12px;
  display: inline-flex;
  font-size: 0.9rem;
  height: 48px;
  justify-content: space-between;
  min-width: 300px;
  padding: 0 12px;
  position: relative;
}

.datefield--invalid {
  border: 2px solid red;
}

.datefield__fields {
  align-items: center;
  display: inline-flex;
  gap: 0.5em;
}
.datefield__fields .datefield__monthpart, .datefield__fields .datefield__daypart, .datefield__fields .datefield__yearpart {
  appearance: none;
  border: none;
  padding: 0;
}
.datefield__fields .datefield__monthpart {
  width: 20px;
}
.datefield__fields .datefield__daypart {
  width: 20px;
}
.datefield__fields .datefield__yearpart {
  width: 40px;
}

.datefield__legend {
  background-color: white;
  font-size: 0.9em;
  padding: 0 0.1em;
  position: absolute;
  top: -0.6em;
  z-index: 1;
}

.datefield__button {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.datefield__input {
  height: 0;
  left: 50%;
  position: absolute;
  opacity: 0;
  top: 50%;
  width: 0;
}

.debouncedTextbox {
  align-items: center;
  background: none;
  border: 1px solid #8396b4;
  border-radius: 12px;
  display: flex;
  font-size: 0.9rem;
  height: 48px;
  justify-content: space-between;
  min-width: 300px;
  outline: none;
  padding: 0 10px;
  position: relative;
}

.debouncedTextbox__legend {
  background-color: white;
  font-size: 0.9em;
  padding: 0 0.1em;
  position: absolute;
  top: -0.65em;
  z-index: 1;
}

.debouncedTextbox .debouncedTextbox__input {
  border: none;
  outline: none;
  width: 100%;
}