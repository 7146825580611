.breadcrumb {
  display: flex;
  margin: 0;
}

.breadcrumb__item {
  align-items: center;
  display: flex;

  &:not(:first-child) {
    &::before {
      content: "/";
      padding: 0 0.5em;
    }
  }

  > a {
    color: var(--colors-primary);
    text-decoration: none;
  }
}
