//todo: separate CSS for different grids

.maindatagrid__table {
  width: 100%;
}

.maindatagrid__table,
.maindatagrid__table th:first-child {
  border-top-left-radius: 15px;
}
.maindatagrid__table,
.maindatagrid__table th:last-child {
  border-top-right-radius: 15px;
}

.maindatagrid__table-header-cell,
.maindatagrid__table-body-cell {
  padding: 0.5em 1em;
  text-align: left;
  vertical-align: middle;
}

.maindatagrid__table-header-cell {
  font-weight: 500;
}

.maindatagrid__table-header-row {
  background-color: #edf3fd;
  height: 3.125em /* 50px */
}

.maindatagrid__table-body-row {
  height: 3.5em; /* 56px */
}
.maindatagrid__table-body-row>.maindatagrid__table-body-cell {
  border-bottom: 1px solid #edf3fd;
}

.maindatagrid__pagination-container {
  padding-top: 0.2em;
}

// .maindatagrid__checkbox {
//   // appearance: none;
//   height: 18px;
//   outline: 2px solid #3168a8;
//   width: 18px;
// }

