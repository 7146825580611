input[type="text"],
select {
  border: 1px solid var(--colors-grey-light);
  border-radius: 0.25em;
  font-size: 1rem;
  outline: none;
  padding: 0.5em;

  &:focus {
    border-color: var(--colors-primary);
  }
}
