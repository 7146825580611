.overlay {
  background-color: rgba(0, 0, 0, .2);
  bottom: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity .45s, visibility .5s;
  visibility: hidden;
  z-index: 10000;
}

.overlay--visible {
  opacity: 1;
  visibility: visible;
  transition: opacity .45s;
}
