.matchedlocationsmodal {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
}

.matchedlocationsmodal__panel {
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  height: max-content;
  width: max-content;
}

.matchedlocationsmodal__body {
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: space-evenly;
  padding: 1.25em;
}

.matchedlocationsmodal__placekey-area {
  display: flex;
  gap: 0.4em;
  justify-content: center;
}
.matchedlocationsmodal__placekey-name {
  font-weight: bold;
}


.matchedlocationsmodal__button-area {
  align-items: center;
  display: flex;
  gap: 1em;
  justify-content: flex-end;
}

.matchedlocationsmodal__title-row .matchedlocationsmodal__close-button {
  padding: 0.5em;
  font-size: 2em;
}



.matchedlocationsmodal__title-row {
  align-items: center;
  display: flex;
  height: 5.125em; /* 82px */
  justify-content: space-between;
}

.matchedlocationsmodal__title-row-side {
  align-items: center;
  display: flex;
}

.matchedlocationsmodal__title-container {
  align-items: center;
  border-left: 0.25em solid #20fffa;
  display: flex;
  height: 2.375em; /* 38px */
  padding-left: 1.25em;
}

.matchedlocationsmodal__title-text {
  color: #131245;
  font-size: 1.125em; /* 18px */
  font-weight: 500;
}


.matchedlocationsmodal__first-row-cell {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.matchedlocationsmodal__first-row-cell-header {
  font-weight: bold;
}

.matchedlocationsmodal__bottom-grid-area {
  border-top: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
  padding: 0.5em 0;
}





