.progress-bar {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.progress-bar__step {
  flex: 1 1 0;

  &:last-child {
    flex-grow: 0;
  }
}

.progress-bar__step--complete {
  > .progress-bar__track {
    &:after {
      background-color: var(--colors-grey);
      background-position: -100% 100%;
    }
  }
}

.progress-bar__track {
  display: block;
  position: relative;

  &:before {
    align-items: center;
    background-color: var(--colors-primary);
    border-radius: 50%;
    color: var(--colors-white);
    content: attr(data-value);
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
  }

  &:after {
    background: linear-gradient(to right, var(--colors-grey-light) 50%, var(--colors-grey) 50%);
    background-color: var(--colors-grey-light);
    background-position: 0% 100%;
    background-size: 200% 100%;
    content: "";
    display: block;
    height: 4px;
    position: absolute;
    top: 10px;
    transition: all 1s ease;
    width: 100%;
    z-index: -1;
  }
}
