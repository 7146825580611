.file-upload {
  margin: 1em 0;
  position: relative;
}

.file-upload__input {
  cursor: pointer;
  height: 40px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 5.75%;
}

.file-upload__file-upload {
  @extend .button;
}

.file-upload__files {
  padding-left: 1em;
}
