.shimmer {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 1200px 100%;
  margin: 0.5em 0 0;
}

@keyframes shimmer {
  0% {
      background-position: -1200px 0;
  }
  100% {
      background-position: 1200px 0;
  }
}

.shimmer--line {
  border-radius: 20px;
}

.shimmer--round {
  border-radius: 50%;

  &.shimmer--small {
    height: 40px;
    width: 40px;
  }

  &.shimmer--normal {
    height: 60px;
    width: 60px;
  }

  &.shimmer--large {
    height: 80px;
    width: 80px;
  }
}

.shimmer--square {
  border-radius: 8px;
  height: 100%;
  width: 100%;
}

.shimmer--small {
  height: 8px;
}

.shimmer--normal {
  height: 14px;
}

.shimmer--large {
  height: 24px;
}

.shimmer--w25 {
  width: 25%;
}

.shimmer--w50 {
  width: 50%;
}

.shimmer--w75 {
  width: 75%;
}

.shimmer--w100 {
  width: 100%;
}
