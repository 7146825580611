.circle {
  background-color: var(--colors-primary);
  border-radius: 50%;
  display: inline-block;
  height: 50px;
  width: 50px;
}

.square {
  background-color: var(--colors-primary);
  display: inline-block;
  height: 50px;
  width: 50px;
}
