.tab {
  display: flex;
  flex-direction: column;
}

.tab__tabs {
  //border-bottom: 1px solid var(--colors-grey-light);
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.tab__tab--full-width {
  width: 100%;
}

.tab__tab {
  & .tab__panel {
    background: none;
    border: 1px solid transparent;
    cursor: pointer;
    margin-bottom: -1px;
    padding: 0.5em 1em;
    position: relative;
    outline: none;
    font-size: 0.875em;
    font-weight: 500;
    color: #20233d;
    height: 3.5em; /* 56px */
  }

  & .tab__panel--active {
    border-bottom: solid 2px #20233d;
    padding-bottom: calc(0.5em - 2px);
    //border-color: var(--colors-grey-light);
    //border-bottom-color: var(--colors-white);
    //border-radius: 4px 4px 0 0;
  }
  
  & .tab__panel--full-width {
    width: 100%;
  }
}

.tab__content {
  display: none;
  //margin: 1em 0 0 0.5em;
  top: 100%;
}

.tab__content--active {
  display: block;
}
