.datefield {
  align-items: center;
  border: 1px solid #8396b4;
  border-radius: 12px;
  display: inline-flex;
  font-size: 0.9rem;
  height: 48px;
  justify-content: space-between;
  min-width: 300px;
  padding: 0 12px;
  position: relative;
}
.datefield--invalid {
  border: 2px solid red;
}

.datefield__fields {
  align-items: center;
  display: inline-flex;
  gap: 0.5em;

  & .datefield__monthpart,
  & .datefield__daypart,
  & .datefield__yearpart {
    appearance: none;
    border: none;
    padding: 0;
  }
  & .datefield__monthpart {
    width: 20px;
  }
  & .datefield__daypart {
    width: 20px;
  }
  & .datefield__yearpart {
    width: 40px;
  }
}

.datefield__legend {
  background-color: white;
  font-size: 0.9em;
  padding: 0 0.1em;
  position: absolute;
  top: -0.6em;
  z-index: 1;
}

.datefield__button {
  align-items: center;
  display: inline-flex;
  justify-content: center;
};

.datefield__input {
  height: 0;
  left: 50%;
  position: absolute;
  opacity: 0;
  top: 50%;
  width: 0;
};
