.calendar-months {
  height: 80%;
  width: 100%; 
}

.calendar-months__calendar {
  height: 100%;
  width: 100%;
}

.calendar-months__header {
  font-size: 0.875rem;
}

.calendar-months__body {
  font-size: 0.875rem;
}

.calendar-months__cell {
  text-align: center;
}

.calendar-months__month {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.calendar-months__month--current {
  background-color: var(--colors-primary);
  border-radius: 2px;
  color: var(--colors-white);
}
