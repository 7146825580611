.bounce-loader {
  height: 60px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
}

@keyframes bounce-animation {
  0%, 80%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.bounce-loader__circle {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &:before {
    animation: bounce-animation 1.2s infinite ease-in-out both;
    background-color: var(--colors-primary);
    border-radius: 50%;
    content: '';
    display: block;
    height: 15%;
    margin: 0 auto;
    width: 15%;
  }

  &:nth-child(2) {
    transform: rotate(30deg);

    &:before {
      animation-delay: -1.1s;
    }
  }

  &:nth-child(3) {
    transform: rotate(60deg);

    &:before {
      animation-delay: -1s;
    }
  }

  &:nth-child(4) {
    transform: rotate(90deg);

    &:before {
      animation-delay: -0.9s;
    }
  }

  &:nth-child(5) {
    transform: rotate(120deg);

    &:before {
      animation-delay: -0.8s;
    }
  }

  &:nth-child(6) {
    transform: rotate(150deg);

    &:before {
      animation-delay: -0.7s;
    }
  }

  &:nth-child(7) {
    transform: rotate(180deg);

    &:before {
      animation-delay: -0.6s;
    }
  }

  &:nth-child(8) {
    transform: rotate(210deg);

    &:before {
      animation-delay: -0.5s;
    }
  }

  &:nth-child(9) {
    transform: rotate(240deg);

    &:before {
      animation-delay: -0.4s;
    }
  }

  &:nth-child(10) {
    transform: rotate(270deg);

    &:before {
      animation-delay: -0.3s;
    }
  }

  &:nth-child(11) {
    transform: rotate(300deg);

    &:before {
      animation-delay: -0.2s;
    }
  }

  &:nth-child(12) {
    transform: rotate(330deg);

    &:before {
      animation-delay: -0.1s;
    }
  }
}
