.dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.dropdown__dropdown {
  align-items: center;
  background: none;
  border: 1px solid #8396b4;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  font-size: 0.9rem;
  height: 48px;
  justify-content: space-between;
  min-width: 300px;
  outline: none;
  padding: 0 10px;
  position: relative;

  // &:focus {
  //   box-shadow: 0 0 4px var(--colors-primary);
  // }
}

.dropdown__legend {
  background-color: white;
  font-size: 0.9em;
  padding: 0 0.1em;
  position: absolute;
  top: -0.85em;
  z-index: 1;
}


.dropdown__dropdown--open {
  .dropdown__caret-cont {
    transform: rotate(180deg);
    transition: transform 0.2s;
  }
}

.dropdown__label {
  font-weight: 500;
}

.dropdown__label--placeholder {
  color: var(--colors-grey);
}

.dropdown__caret-cont {
  transition: transform 0.2s;
}

.dropdown__arrow {
  border: 6px solid rgb(128, 128, 128);
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  display: inline-block;
  height: 0;
  margin: 8px 0 0 10px;
  width: 0;
}

.dropdown__arrow--open {
  border-top-color: transparent;
  border-bottom-color: rgb(128, 128, 128);
  margin-top: -5px;
}

.dropdown__items {
  @extend .custom-scrollbar;

  background-color: var(--colors-white);
  border-radius: 4px;
  border: 1px solid var(--colors-grey-light);
  display: none;
  list-style-type: none;
  margin: 3px 0 0;
  max-height: 300px;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 999;
}

.dropdown__items--open {
  display: block;
}

.dropdown__item {
  background-color: var(--colors-white);
  display: flex;
  border: none;
  cursor: pointer;
  justify-content: space-between;
  font-size: 0.9rem;
  outline: none;
  padding: 1.1em 0.8em;
  width: 100%;

  &:focus,
  &:hover {
    background-color: rgb(250, 250, 250);
  }
}

.dropdown__item--selected {
  background-color: rgb(238, 238, 238);

  > .dropdown__item-checked {
    display: initial;
  }
}

.dropdown__item-checked {
  display: none;
  padding-right: 0.8em;
  position: relative;

  &:after {
    content: "";
    border: solid var(--colors-green);
    border-width: 0 2px 2px 0;
    height: 14px;
    position: absolute;
    transform: rotate(45deg);
    width: 6px;
  }
}
