.locationmapping {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1.25em; /* 20px */
  padding-bottom: 3em; /*48px */
}

.locationmapping__filter-row {
  border-bottom: 1px solid #ebeef3;
  border-top: 1px solid #ebeef3;
  display: flex;
  gap: 1em;
  justify-content: flex-start;
  padding: 1.5em 0;
}

.locationmapping__title-row {
  align-items: center;
  display: flex;
  height: 5.125em; /* 82px */
}

.locationmapping__title-container {
  align-items: center;
  border-left: 0.25em solid #20fffa;
  display: flex;
  gap: 2em;
  height: 2.375em; /* 38px */
  padding-left: 1.25em;
}

.locationmapping__title-row,
.locationmapping__controls-row-top {
  margin-left: -1.5em;
}

.locationmapping__filter-button-row {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 6.25em; /* 100px */
}

.locationmapping__title-element {
  display: inline-flex;
  gap: 0.5em;
  align-items: baseline;
}
.locationmapping__title-text,
.locationmapping__title-pipe {
  color: #131245;
  font-size: 1.125em; /* 18px */
  font-weight: 500;
}

.locationmapping__controls-row-top,
.locationmapping__controls-row-bottom {
  align-items: center;
  display: flex;
}
.locationmapping__controls-row-top {
  height: 5.125em; /* 82px */
  justify-content: space-between;
  border-bottom: 1px solid #edf3fd;
}
.locationmapping__controls-row-bottom {
  height: 6.5em; /* 104px */
  justify-content: flex-end;
  gap: 1.25em; /* 20px */
}

.locationmapping__controls-row-left-side,
.locationmapping__controls-row-right-side {
  align-items: center;
  display: flex;
  gap: 1.25em; /* 20px */
}
.locationmapping__controls-row-right-side {
  align-self: flex-end;
}




.locationmapping__top-card,
.locationmapping__bottom-card {
  background-color: white;
  border-radius: 15px;
  padding: 0 1.5em; /* 56px 24px */
}
.locationmapping__bottom-card {
  flex: 1;
}

.locationmapping__center {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 2em;
}

.locationmapping__dropdown-row {
  align-items: center;
  display: flex;
  justify-content: center;
}

.locationmapping__content-area {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: max-content;
}

.locationmapping__filter-row2 {
  border: 1px solid black;
  display: flex;
  gap: 1em;
  justify-content: space-around;
  padding: 1em 0;
}

.locationmapping__mapping-type-row {
  display: flex;
  justify-content: center;
  padding: 0.5em 0;
}

.locationmapping__control,
.locationmapping__mapping-type-control {
  align-items: center;
  display: flex;
  gap: 1em;
}

.locationmapping__mapping-type-control {
  border-bottom: 2px solid black;
  padding-bottom: 0.5em;
}

// .locationmapping__controls-row {
//   border: 1px solid black;
//   display: flex;
//   align-items: center;
//   gap: 1em;
//   min-height: 70px;
//   padding: 1em;
// }

.locationmapping__grid-area {
  // width: 100%;
  padding-top: 1.25em /* 20px */
}

.locationmapping__date-field {
  height: 48px;
}

.locationmapping__mapping-type-label {
  font-weight: bold;
}
