.homepage {
  background-color: #eaeaea;
  display: flex;
  justify-content: center;
  min-height: 100%;
}

.homepage__centered {
  display: flex;
  flex-direction: column;
  width: calc(100% - 2.25em);
}

.homepage__title-area {
  display: flex;
  justify-content: space-between;
  padding: 1em 2em;
}

.homepage__title-and-logo {
  display: flex;
  gap: 1em;
}

.homepage__title {
  font-size: 1.1em;
  font-weight: 500;
  color: #333;
}
