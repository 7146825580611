.slider {
  position: relative;
}

.slider__prev {
  align-items: center;
  background-color: var(--colors-grey-light);
  border: none;
  border-radius: 50%;
  color: var(--colors-white);
  cursor: pointer;
  display: flex;
  height: 20px;
  left: -30px;
  outline: none;
  opacity: 0.5;
  padding-left: 6px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;

  &:hover {
    opacity: 1;
  }
}

.slider__move-icon {
  &:after {
    border-bottom-width: 3px;
    border-right-width: 3px;
  }
}

.slider__move-icon--prev {
  @extend .chevron;
  @extend .chevron--left;
}

.slider__move-icon--next {
  @extend .chevron;
  @extend .chevron--right;
}

.slider__slider {
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.slider__slide {
  scroll-snap-align: start;
}

.slider__next {
  align-items: center;
  background-color: var(--colors-grey-light);
  border: none;
  border-radius: 50%;
  color: var(--colors-white);
  cursor: pointer;
  display: flex;
  height: 20px;
  outline: none;
  opacity: 0.5;
  padding-right: 6px;
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;

  &:hover {
    opacity: 1;
  }
}

.slider__dots {
  bottom: -30px;
  display: flex;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.slider__dot {
  background-color: var(--colors-grey-light);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 14px;
  margin: 0 10px;
  outline: none;
  width: 14px;

  &:hover {
    background-color: var(--colors-grey);
  }
}

.slider__dot--active {
  background-color: var(--colors-grey);
}
