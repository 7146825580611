:root {
  --colors-primary: #2196F3;
  --colors-white: #fff;
  --colors-black: #000;
  --colors-error: #ff0000;
  --colors-green: green;
  --colors-grey: #979797;
  --colors-grey-light: #ccc;
  --toast-background-color: #323232;
  --toggle-checked-track-color: #3168a8;
  --toggle-unchecked-track-color: #999;
  --range-slider-track-color: #e9e9e9;
}
  